import React, { FC } from 'react';
import classNames from 'classnames';

import ContactInformation from 'layout/Footer/ContactInformation';
import Connect from 'layout/Footer/Connect';
import NavigationList from 'layout/Footer/NavigationList';
import AdditionalInfo from 'layout/Footer/AdditionalInfo';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsTopFooter } from './model';
import './TopFooter.scss';
import './ShadowTopFooter.scss';

const TopFooter: FC<IPropsTopFooter> = ({
  footer: {
    helpAndSupport,
    connect,
    footerNavigation,
    helpAndSupportConnected,
    footerNavigationTitle,
    additionalInfo,
    additionalCopy,
  },
}) => (
  <div
    data-test="topFooter"
    className={classNames('top-footer', {
      'with-contact-information': Boolean(
        helpAndSupport?.[0]?.properties || helpAndSupportConnected?.[0]?.properties
      ),
    })}
  >
    <ContactInformation
      helpAndSupport={helpAndSupport}
      helpAndSupportConnected={helpAndSupportConnected}
    />
    {connect?.length ? <Connect connect={connect} /> : null}
    <div className={classNames({ 'top-footer__additional': additionalCopy?.length > 0 })}>
      <DangerouslySetInnerHtml html={additionalCopy} className="top-footer__additional-copy" />
      <NavigationList
        footerNavigation={footerNavigation}
        footerNavigationTitle={footerNavigationTitle}
      />
    </div>
    {additionalInfo ? <AdditionalInfo additionalInfo={additionalInfo} /> : null}
  </div>
);

export default TopFooter;
